@import "../../../vars";

.lifters-index {
  height: 100%;

  .util-row {
    height: 48px;
    align-items: flex-start;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    max-width: 94vw;

    .drawer,
    .generate-lot-numbers-button {
      margin-left: 12px;
    }
  }

  .weight-class-change {
    color: red;
    font-weight: bold;
  }

  .table-wrapper {
    height: calc(100% - 48px);
  }
}

.generate-lot-numbers-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 190;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($white, 0.2);
}

.generate-lot-numbers-modal {
  background-color: $darkestgray;
  border: 1px solid $bordercolor;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
  overflow: auto;
  max-height: 84vh;
  max-width: 500px;

  .content {
    padding: 0 30px;
    overflow: auto;

    .checkbox {
      margin-bottom: 0;
      min-height: 22px;
    }
  }

  .button-row {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    padding: 0 30px;
  }

  .bulk-edit-row {
    margin-top: 12px;
    max-width: 200px;
  }
}
