@import '../../vars';

.contact {
  padding-top: 20px;

  .contact-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }

  button {
    width: 90vw;
    max-width: 300px;
    margin-bottom: 20px;
  }

}
