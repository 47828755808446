@import "../../../vars";

.setup-index {
  width: 100%;
  max-width: 1100px;
  margin-bottom: 50px;
  label {
    white-space: nowrap;
  }

  .data-file-button {
    margin-top: 20px;
  }

  .sync-error-message {
    padding: 20px 20px;
    font-size: 22px;
    color: red;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid $bordercolor;
    border-radius: 4px;
  }

  .entry-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 12px 0;
    align-items: center;

    label {
      width: 110px;
      text-align: right;
      padding-right: 8px;
    }

    .input-wrapper {
      width: calc(100% - 90px);
    }
  }

  .error-message {
    color: red;
  }

  .meet-info,
  .config-item {
    padding: 0 20px;
  }

  .config-item {
    padding-bottom: 20px;
  }

  .meet-info,
  .config-item,
  .platforms,
  .plates,
  .entry-config,
  .medal-counts,
  .drug-test-selection-helper {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid $bordercolor;
    border-radius: 4px;
    overflow: hidden;

    table {
      padding: 12px;
    }
  }

  .medal-counts,
  .drug-test-selection-helper {
    h2 {
      text-align: center;
      margin-top: 0;
      margin-bottom: 20px;
    }
    padding: 20px;
    .medal-counts-message {
      margin-bottom: 20px;
    }

    table {
      font-size: 18px;
      td:first-child {
        text-align: right;
      }
    }
  }

  .platforms {
    table {
      width: 100%;

      td {
        text-align: center;
      }
    }
  }

  .plates {
    overflow: visible; // make sure we can always see the color picker popup.
    table {
      width: 275px;
    }
  }

  .entry-config {
    padding: 0 12px 12px;

    .registration-title {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      padding: 12px;
    }

    table {
      width: 100%;

      .checkbox {
        svg {
          margin: 0 auto;
        }
      }
    }

    table.sale-items {
      td:nth-child(1) {
        width: 70%;
      }
      td:nth-child(2) {
        width: 20%;
      }
      td:nth-child(3) {
        width: 10%;
      }
    }

    hr {
      border: 0.5px solid $bordercolor;
      width: 75%;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .entry-row {
      label {
        width: 180px;
      }

      .input-wrapper {
        width: calc(100% - 185px);
      }
    }
  }
}
