@import "../../vars";

.about {
  padding-top: 20px;
  margin-bottom: 1000px;

  .question {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid $bordercolor;
    border-radius: 4px;
    max-width: 1024px;

    svg {
      width: 18px;
      height: 18px;
      fill: $lightestgray;

      &.sync-active {
        fill: $green;
      }

      &.sync-disabled {
        fill: $red;
      }
    }

    .question-title {
      display: block;
      background-color: $table-header-color;
      padding: 8px;
      border-bottom: 1px solid $bordercolor;
      font-size: 22px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    .question-answer {
      font-size: 16px;
      padding: 8px;
    }
  }
}
