@import '../../vars';

.lights {
  display: flex;
  justify-content: space-around;
  min-width: 160px;
  height: 55px;

  .decision {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .cards {
    width: 40px;
    height: 10px;
    display: flex;
    border: 1px solid $bordercolor;

    .card {
      flex: 1;
      height: 100%;
      background-color: transparent;

      &.active {
        &.red {
          background-color: $red;
        }

        &.blue {
          background-color: $blue;
        }

        &.yellow {
          background-color: $yellow;
        }
      }
    }
  }

  .light {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: 1px solid $bordercolor;
    display: flex;
    justify-content: center;
    align-items: center;

    &.good {
      background-color: $white;
    }

    &.bad {
      background-color: $red;
    }

    svg {
      height: 24px;
      width: 24px;
      fill: $lightestgray;
    }
  }
}
