@import '../../../vars';

.roster-index {
  padding-bottom: 100px;
  font-size: 18px;
  padding-left: 0;

  .session-roster {
    border: 1px solid $bordercolor;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 20px;

    .session-content {
      display: flex;
      flex-wrap: wrap;
    }
  }

  ul {
    margin-bottom: 20px;
    padding-left: 20px;
    list-style: none;
  }

  .platform-list-wrapper {
    padding-right: 30px;

    .platform-list {
      padding-left: 0;
    }
  }

  .meet-info-row {
    margin-bottom: 10px;
  }
}
