@import '../../../vars';

.team-index {
  height: 100%;

  .scored-column {
    font-weight: bold;
  }

  .team-name {
    font-size: 24px;
  }

  .table-wrapper {
    height: calc(100% - 28px);
  }
}
