@import '../../../vars';

.stats-index {
  display: flex;
  flex-wrap: wrap;

  .session-block {
    display: flex;
    flex-wrap: wrap;
  }

  .stat {
    min-width: 360px;
    border: 1px solid $bordercolor;
    border-radius: 4px;
    padding: 20px;
    margin: 20px;
  }
}
