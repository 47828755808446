@import "../../vars";

.grid-bottom-left {
  border-right: 1px solid $bordercolor;
  border-bottom: 1px solid $bordercolor;
  border-left: 1px solid $bordercolor;
}

.grid-bottom-right {
  border-right: 1px solid $bordercolor;
  border-bottom: 1px solid $bordercolor;
  overflow-y: scroll !important;
}

.grid-top-left {
  border-right: 1px solid $bordercolor;
  border-left: 1px solid $bordercolor;
}

.grid-top-right {
  border-right: 1px solid $bordercolor;
}

.grid-top-left,
.grid-top-right {
  border-top: 1px solid $bordercolor;
  border-bottom: 1px solid $bordercolor;
  font-weight: bold;
  background-color: $table-header-color;
}

.grid-bottom-left,
.grid-top-left,
.grid-top-right {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
}

.table-cell.table-header {
  overflow: hidden;
  white-space: normal;

  .sortable-header {
    cursor: pointer;
    height: 100%;
  }

  svg {
    height: 20px;
    width: 20px;
    float: right;
    fill: $lightestgray;
  }
}

.table-cell {
  box-sizing: border-box;
  border-bottom: 1px solid $bordercolor;
  border-left: 1px solid $bordercolor;
  white-space: nowrap;
  font-size: 14px;
  background-color: $darkestgray;
  color: $lightestgray;
  font-weight: normal;

  &.table-cell-extra-left-border {
    border-left: 2px solid $bordercolor;
    &.table-cell-title {
      border-left: 0;
    }
  }

  &.even {
    background-color: lighten($darkestgray, 11%);

    .table-cell-inner {
      &.good {
        background-color: lighten($green, 3%);
        input {
          background-color: lighten($green, 3%);
          color: $lightestgray;
        }
      }

      &.bad {
        background-color: lighten($red, 3%);
        input {
          background-color: lighten($red, 3%);
          color: $lightestgray;
        }
      }
    }
  }

  &.first-column {
    border-left: none;
  }

  .button-cell {
    button {
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .table-cell-inner {
    padding: 6px;
    height: 100%;

    &.good {
      background-color: $green;
      input {
        background-color: $green;
        color: $lightestgray;
      }
    }

    &.bad {
      background-color: $red;
      input {
        background-color: $red;
        color: $lightestgray;
      }
    }

    &.current-attempt,
    &.good.current-attempt,
    &.bad.current-attempt {
      background-color: #333366;
    }
  }

  .inner-row {
    height: 42px;
  }

  .cell-row {
    border-top: 1px solid $bordercolor;
    padding: 6px 0;

    &:first-child {
      border-top: none;
      padding-top: 0 !important;
    }
  }

  button {
    font-size: 14px;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }
}

.table-cell-title {
  white-space: nowrap;
  font-weight: bold;
  border: 0;
  border-bottom: 1px solid $bordercolor;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.first-column {
    z-index: 1;
  }
}

.medium.table-header {
  font-size: 11px;

  svg {
    height: 15px;
    width: 15px;
  }
}

.medium.table-cell {
  font-size: 11px;

  .table-cell-inner {
    padding: 4px;
  }

  .inner-row {
    height: 32px;
  }

  .cell-row {
    padding: 4px 0;
  }

  button {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 11px;
    height: 27px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  input[type="text"],
  input[type="number"] {
    font-size: 11px;
    padding: 4px;
    height: 27px;
    border-radius: 3px;
  }

  .custom-select {
    height: 27px;
    border-radius: 3px;
    font-size: 9px;
    padding-left: 1px;
    padding-right: 1px;
  }
}

.small.table-header {
  font-family: $narrow-font;
  font-size: 9px;

  svg {
    height: 10px;
    width: 10px;
  }
}

.small.table-cell {
  font-family: $narrow-font;
  font-size: 9px;

  .table-cell-inner {
    padding: 3px;
  }

  .inner-row {
    height: 24px;
  }

  .cell-row {
    padding: 3px 0;
  }

  button {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 9px;
    height: 19px;

    svg {
      width: 13px;
      height: 13px;
    }
  }

  input[type="text"],
  input[type="number"] {
    font-size: 9px;
    padding: 3px;
    height: 19px;
    border-radius: 2px;
  }

  .custom-select {
    height: 19px;
    border-radius: 2px;
    font-size: 9px;
    padding-left: 0;
    padding-right: 0;
  }
}
