@import "../../../vars";

.records-index {
  height: 100%;

  .util-row {
    height: 48px;
    align-items: flex-start;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    max-width: 94vw;

    .drawer {
      margin-left: 12px;
    }
  }

  .table-wrapper {
    height: calc(100% - 48px);
  }
}
