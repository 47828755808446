@import '../../../vars';

.ref {
  max-width: 600px;

  .position {
    padding: 8px;
    text-transform: capitalize;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .clock {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5vh;

    button {
      width: 48%;
      height: 40px;
    }
  }

  .current {
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    .current-attempt {
      text-transform: capitalize;
    }
  }

  .icon-wrapper {
    margin-top: 1vh;

    svg {
      width: 5vh;
      height: 5vh;
    }
  }

  button {
    height: 12vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fault-cards {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5vh;

    .fault-card {
      color: white;
      width: 31%;

      svg {
        fill: white;
      }

      &.red {
        background-color: $red;
      }

      &.blue {
        background-color: $blue;
      }

      &.yellow {
        background-color: $yellow;
      }
    }
  }

  .button.good {
    &.selected {
      border: 5px solid $white;
    }

    svg {
      fill: $white;
    }
  }

  .button.bad {
    &.selected {
      border: 5px solid $red;
    }

    svg {
      fill: $red;
    }
  }

  .button.good, .button.bad {
    margin-top: 2.5vh;
    width: 100%;
    position: relative;

    .icon-wrapper {
      height: 100%;
      svg {
        width: 8vh;
        height: 8vh;
      }
    }

    .check-icon-wrapper {
      position: absolute;
      bottom: 0;
      top: 0;
      right: 6vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      svg {
        width: 8vh;
        height: 8vh;
      }
    }
  }

  .button.record {
    width: 100%;
    margin-top: 2.5vh;

    svg {
      position: relative;
      top: 5px;
    }
  }

  .lights {
    margin-top: 20px;
  }
}
