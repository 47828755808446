@import '../../vars';

.radio-group {
  display: flex;
  flex-direction: column;

  .radio {
    display: flex;
    cursor: pointer;
    margin-bottom: 4px;
    align-items: center;
    white-space: nowrap;

    svg {
      width: 18px;
      height: 18px;
      fill: $lightestgray;
      margin-right: 4px;
    }
  }
}
