@import '../../vars';

.adjust-columns-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 190;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba( $white, .2 )
}

.adjust-columns-modal {
  background-color: $darkestgray;
  border: 1px solid $bordercolor;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
  overflow: auto;
  max-height: 60%;

  .content {
    padding: 0 30px;

    input {
      width: 80px;
    }

    td {
      min-width: 100px;
    }
  }

  .button-row {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    padding: 0 30px 30px;
  }
}
