@import "../../../vars";

.jury {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 400px 1fr;
    gap: 20px;
  }

  .jury-attempt-list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .jury-attempt {
      border: 1px solid $bordercolor;
      &.current {
        border: 3px solid $bordercolor;
      }
      border-radius: 4px;
      padding: 12px;

      .jury-lift-info {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        margin-bottom: 12px;

        svg {
          margin-left: 8px;
          height: 20px;
          width: 20px;
          &.bad {
            fill: $red;
          }
          &.good {
            fill: $green;
          }
        }
      }

      .lights {
        max-width: 260px;
        margin: auto;
      }

      .jury-override-lights {
        position: relative;
      }

      .jury-button-row {
        margin-top: 12px;
        display: flex;
        justify-content: space-around;

        button {
          svg {
            margin-left: 8px;
          }

          &.red {
            background-color: $red;
          }
          &.blue {
            background-color: $blue;
          }
          &.yellow {
            background-color: $yellow;
          }
        }
      }
    }
  }
}
