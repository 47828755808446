@import "../../../vars";

.import-index {
  height: 100%;

  .import {
    height: 100%;
  }

  .util-row {
    height: 48px;
    align-items: center;
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
  }

  .table-wrapper {
    height: calc(100% - 92px);
  }

  .mismatch-icon-wrapper {
    height: 1em;
    svg {
      fill: red;
      width: 1em;
      height: 1em;
    }
  }
}

.small {
  .import-index {
    .util-row {
      justify-content: space-between;

      button,
      .uploader {
        margin-right: 0;
        font-size: 12px;
        max-width: 110px;
        white-space: normal;
      }
    }
  }
}

.info-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 190;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($white, 0.2);
}

.info-modal {
  background-color: $darkestgray;
  border: 1px solid $bordercolor;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  overflow: auto;
  max-height: 96vh;
  max-width: 800px;

  .content {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    li {
      margin-bottom: 10px;
    }
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    padding: 0 30px;
  }
}
