$blue: #0000CC;
$red: #CC0000;
$yellow: #CCCC00;
$green: #006600;

$white: #FFFFFF;
$lightestgray: #F0F0F0;
$lightgray: #DDDDDD;
$bordercolor: #A9A9A9;
$darkgray: #444444;
$darkestgray: #000000;
$black: #000000;

$table-header-color: #242424;

$regular-font: 'Roboto', sans-serif;
$narrow-font: 'Roboto Condensed', sans-serif;
