@import '../../vars';

.file-upload {
  border-radius: 4px;
  display: inline-block;
  border: none;
  padding: 0 12px;
  font-size: 14px;
  background-color: #444444;
  cursor: pointer;
  white-space: nowrap;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background-color: #555555;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: $lightestgray;
  }
}
