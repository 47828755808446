@import '../../../vars';

.division-index {
  height: 100%;
  width: 100%;

  .util-row {
    height: 48px;
    align-items: flex-start;
    display: flex;
    white-space: nowrap;

    button.forecasted {
      width: 150px;
    }
  }

  .division-title {
    height: 40px;
    text-align: center;
    font-size: 24px;
  }

  .table-wrapper {
    height: calc(100% - 88px);
  }
}