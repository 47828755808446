@import "./vars";

.custom-select {
  min-height: 0 !important;
  height: 36px;
  color: $darkestgray;
  background-color: $lightestgray;
  border: none;
  padding-left: 6px;
  padding-right: 3px;
  font-size: 14px;
  border-radius: 4px;
  display: block;
  width: 100%;

  &.error {
    border: 1px solid red;
  }

  option {
    padding-left: 0;
    padding-right: 0;
  }
}
