@import "../../../vars";

.registration-index {
  position: relative;
  min-height: 70vh;
  max-width: 600px;
  margin-top: 20px;
  margin-bottom: 200px;
  border: 1px solid $bordercolor;
  border-radius: 4px;
  padding: 12px;
  overflow: hidden;

  .line-items-table {
    width: 100%;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-collapse: collapse;
    tr {
      &:last-child {
        font-weight: bold;
        td {
          border: none;
        }
      }
      td {
        border-bottom: 1px solid white;
        padding-bottom: 8px;
        padding-top: 8px;
        text-align: right;
        &:first-child {
          padding-right: 10px;
        }
      }
    }
  }

  .message {
    font-size: 24px;
  }

  .total {
    margin-top: 50px;
    font-size: 36px;
    text-align: right;
  }

  .pay-button {
    width: 100%;
    text-align: center;
    padding: 12px 0px;
    height: auto;
    font-size: 24px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .divisions-title {
    padding-top: 20px;
    font-size: 22px;
  }

  .items-title,
  .disclaimer-title {
    padding-top: 20px;
    font-size: 22px;
    border-bottom: 1px solid $bordercolor;
    margin-bottom: 20px;
  }

  .items-subtitle {
    margin-bottom: 8px;
  }

  .add-division {
    padding-bottom: 20px;
  }

  .division-box {
    border-top: 1px solid $bordercolor;
    padding: 20px 0;

    .division-count {
      font-size: 18px;
      padding-bottom: 12px;
    }

    button {
      float: right;
    }
  }

  table.item-table {
    width: 100%;
    margin-bottom: 8px;

    td {
      padding: 4px;
    }

    td:first-child {
      width: 88%;
    }

    td:last-child {
      width: 12%;
    }
  }

  .meet-info {
    margin-bottom: 24px;
    font-size: 18px;
    white-space: pre-line;

    .website-link {
      margin-top: 20px;
    }
  }

  .entry-row {
    margin-bottom: 18px;
    font-size: 16px;

    .error-message {
      text-align: right;
      color: $red;
    }

    input.error,
    .Select.error {
      border: 2px solid $red;
    }

    .input-wrapper {
      margin-top: 2px;
    }

    .date-input {
      div.error {
        font-size: 14px;
      }
    }
  }

  .disclaimer-text {
    font-size: 18px;
    margin: 24px 0;
    white-space: pre-line;
  }

  .api-error {
    font-size: 18px;
    color: red;
    margin: 50px 0;
  }

  .processing-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($white, 0.2);
    color: black;
    font-size: 42px;
    z-index: 100000000000;
  }

  .processing-spinner {
    position: absolute;
    top: 5%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 100000000000;

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 200px;
      height: 200px;
    }
    .loader {
      margin: 60px auto;
      font-size: 10px;
      position: relative;
      text-indent: -9999em;
      border-top: 25px solid rgba(0, 0, 0, 0.2);
      border-right: 25px solid rgba(0, 0, 0, 0.2);
      border-bottom: 25px solid rgba(0, 0, 0, 0.2);
      border-left: 25px solid #000;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}
