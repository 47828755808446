.membership-checker-index {
  height: 100%;

  .util-row {
    height: 48px;
    align-items: flex-start;
    display: flex;
    white-space: nowrap;
  }

  .table-wrapper {
    height: calc(100% - 48px);
  }

  .error {
    color: red;
  }
}
