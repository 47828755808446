@import '../../vars';

.instructions {
  padding-top: 20px;

  .frame-wrapper {

    iframe {
      border: 1px solid $bordercolor;
      border-radius: 4px;
      height: calc(100vh - 120px);
      width: 850px;
    }
  }
}
