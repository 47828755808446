@import "../../vars";

.invoice-status {
  border: 1px solid $bordercolor;
  border-radius: 4px;
  overflow: scroll;
  min-height: 150px;
  padding: 20px 20px;
  font-size: 18px;

  button {
    margin-top: 20px;
  }
}
