@import '../../vars';

.checkbox {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 4px;
  white-space: nowrap;

  svg {
    width: 18px;
    height: 18px;
    fill: $lightestgray;
    margin-right: 4px;
  }

  &.disabled {
    color: gray;
    svg {
      fill: gray;
    }
  }
}
