@import "../../../../vars";

.cards-index {
  box-sizing: border-box;

  .print-note {
    margin-bottom: 20px;
  }

  .cards-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  .cards-version1 {
    .card-page {
      display: grid;
      grid-template-columns: repeat(2, 495px);
      grid-auto-rows: 370px;
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      justify-items: center;
      align-items: center;
      margin-bottom: 40px;
    }

    .card {
      display: grid;
      grid-template-columns: repeat(10, 10%);
      grid-auto-rows: 8.333333333%; //9.0909%;
      width: 100%;
      height: 100%;
      margin: 7px auto;
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      position: relative;
      overflow-y: hidden;
      background-color: white;
      color: black;

      label {
        font-size: 10px;
        margin-right: 2px;
      }
    }

    .card-cell {
      display: flex;
      align-items: center;
      padding-left: 4px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      font-size: 14px;

      // for some reason this doesn't work with css grid
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.barcode-cell {
        justify-content: center;
      }

      &.change-cell {
        background-color: $lightestgray;
      }

      &.right-cell {
        border-right: none;
      }

      &.bottom-cell {
        border-bottom: none;
      }

      &.shrink {
        font-size: 12px;
      }
    }
  }

  .cards-version2 {
    .card {
      width: 1000px;
      height: 660px;
      padding-top: 35px;
      padding-bottom: 55px;
      padding-right: 20px;
      padding-left: 20px;
      margin: 7px auto;
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      font-size: 18px;
      position: relative;
      overflow-y: hidden;
      background-color: white;
      color: black;

      .field {
        border-bottom: 1px solid black;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .title {
        font-size: 22px;
        font-weight: 900;
        padding-bottom: 10px;
        margin-left: 40px;
      }

      .rack-heights {
        padding: 5px;
        position: absolute;
        top: 5px;
        right: 15px;
      }

      .rack-height-label {
        width: 210px;
        margin-top: 12px;
      }

      .rack-height {
        width: 80px;
        margin-top: 12px;
      }

      .row {
        padding-top: 10px;
        padding-bottom: 10px;
        height: 42px;
        display: flex;
      }

      label {
        white-space: nowrap;
        padding-right: 12px;
        padding-left: 12px;
        text-align: right;
        display: inline-block;
        font-weight: bold;

        &.lot-label {
          width: 100px;
        }

        &.platform-label {
          width: 100px;
        }

        &.session-label {
          width: 100px;
        }

        &.flight-label {
          width: 80px;
        }

        &.name-label {
          width: 100px;
        }

        &.division-label {
          width: 100px;
        }

        &.weight-class-label {
          width: 150px;
        }

        &.age-label {
          width: 100px;
        }

        &.birth-date-label {
          width: 120px;
        }

        &.lift-label {
          width: 150px;
          text-transform: capitalize;
        }

        &.attempt-label {
          margin-top: 20px;
          width: 160px;
          text-align: center;
        }

        &.total-label {
          margin-top: 10px;
          width: 660px;
          text-align: right;
        }
      }

      .name {
        width: 400px;
        overflow: hidden;
        white-space: nowrap;
      }

      .division {
        width: 390px;
        overflow: hidden;
        white-space: nowrap;
      }

      .weight-class {
        width: 317px;
        overflow: hidden;
        white-space: nowrap;
      }

      .body-weight-label {
        width: 135px;
      }

      .body-weight {
        width: 125px;
        overflow: hidden;
        white-space: nowrap;
      }

      .age {
        width: 65px;
        overflow: hidden;
        white-space: nowrap;
      }

      .birth-date {
        width: 105px;
      }

      .lot {
        width: 50px;
      }

      .platform {
        width: 320px;
      }

      .session {
        width: 25px;
      }

      .flight {
        width: 25px;
      }

      .attempt {
        width: 150px;
        margin-top: 10px;
        margin-right: 20px;
        text-align: right;
        padding-top: 14px;
        height: 38px;
      }
    }
  }

  .cards-attempt {
    .card-page {
      display: grid;
      grid-template-columns: repeat(3, 310px);
      grid-auto-rows: 370px;
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      justify-items: center;
      align-items: center;
      margin-bottom: 40px;
    }

    .card {
      display: grid;
      grid-template-columns: repeat(9, 11.11%);
      grid-auto-rows: 6.666%;
      width: 100%;
      height: 100%;
      margin: 7px auto;
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      position: relative;
      overflow-y: hidden;
      background-color: white;
      color: black;
    }

    .card-cell {
      display: flex;
      align-items: center;
      padding-left: 4px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      font-size: 14px;
    }
  }
}

@media print {
  @page {
    margin: 0.5cm;
  }

  body {
    background-color: $white !important;
  }

  .print-note {
    display: none;
  }

  .cards-index {
    height: auto;
    color: $black;
    background-color: $white;

    div {
      overflow: hidden;
    }

    .cards-version1 {
      .card-page {
        page-break-after: always;
      }

      .card {
        border-radius: 0;
      }
    }

    .cards-version2 {
      .card {
        border-style: none;

        &.even {
          page-break-after: always;
        }
      }
    }

    .cards-attempt {
      .card-page {
        page-break-after: always;
      }

      .card {
        border-radius: 0;
      }
    }
  }
}
