@import "../../../vars";

.divisions-index {
  height: 100%;

  .util-row {
    height: 48px;
    align-items: flex-start;
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    max-width: 94vw;

    .drawer {
      margin-left: 12px;
    }
  }

  .table-wrapper {
    height: calc(100% - 48px);
  }
}

.generate-divisions-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 190;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($white, 0.2);
}

.generate-divisions-modal {
  background-color: $darkestgray;
  border: 1px solid $bordercolor;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
  overflow: auto;
  max-height: 96vh;
  max-width: 800px;

  .division-generate-message {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: center;

    &.error {
      color: $red;
    }
  }

  .content {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    .first-columns {
      display: flex;
    }

    .column {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
    }

    .checkbox {
      margin-bottom: 0;
      min-height: 22px;
    }
  }

  .button-row {
    display: flex;
    justify-content: flex-end;
    padding: 0 30px;
  }
}

@media (max-width: 640px) {
  .generate-divisions-modal {
    .content {
      .first-columns {
        flex-direction: column;

        .column {
          padding-bottom: 30px;
        }
      }
    }
  }
}
