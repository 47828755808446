@import '../../vars';

.new-meet-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 190;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba( $white, .2 )
}

.new-meet-modal {
  background-color: $darkestgray;
  border: 1px solid $bordercolor;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
  overflow: auto;
  max-width: 96vw;
  max-height: 84vh;
  width: 480px;

  .content {
    padding: 0 30px;

    .title {
      text-align: center;
      font-size: 24px;
    }

    .info {
      margin-bottom: 24px;
      font-size: 18px;

    }

    .row {
      margin-bottom: 18px;
      font-size: 16px;

      .error-message {
        text-align: right;
        color: $red;
      }

      input.error, .Select.error {
        border: 2px solid $red;
      }

      .input-wrapper {
        margin-top: 2px;
      }

      .date-input {
        div.error {
          font-size: 14px;
        }
      }
    }
  }

  .button-row {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    padding: 0 30px;
    margin-bottom: 30px;
  }
}
