@import "../../vars";

.color-picker-popover {
  .color-picker-popover-arrow {
    top: 1px !important;
  }
  .react-colorful__saturation {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 201px;
  }
  .react-colorful__last-control {
    border-radius: 0px;
    width: 201px;
  }
  .color-picker-popover-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 4;
    background-color: black;
    border: 2px solid white;
    border-radius: 4px;
  }

  .color-picker-input-row {
    border-top: 1px solid black;
    input {
      width: 100%;
      border: none;
      height: 30px;
    }
  }

  .color-picker-bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      width: 49.5%;
      border-radius: 0;
    }
  }

  .color-picker-preview {
    height: 50px;
  }
}

.color-picker {
  .color-picker-swatch {
    padding: 5px;
    margin-top: 3px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;

    .swatch-inner {
      width: 60px;
      height: 26px;
      border-radius: 4px;
    }
  }
}
