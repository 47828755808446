@import "../../../vars";

$platform-border-color: #dddddd;
$platform-main-background-color: #222222;
$platform-best-lifts-background-color: #0071bc;
$platform-current-attempt-font-color: #736357;

.live-feed-index {
  height: calc(100% - 60px);
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 100px;
  font-size: 24px;
  white-space: nowrap;

  .live-feed-content {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 100px;
  }

  .color-picker-wrapper {
    display: flex;
    flex-direction: row;

    label {
      width: 220px;
    }
  }

  .platform3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    margin: 100px;

    .flag {
      position: relative;
      height: 100px;
      width: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top left;
      display: none;
    }

    .platform-lights {
      opacity: 0;
      height: 80px;
      position: relative;
      top: 6px;
      display: flex;
      flex-direction: row;
      transition: opacity 250ms ease-in-out;
      &.platform-lights-active {
        opacity: 1;
      }

      .platform-lights-wrapper {
        border-top: 6px solid $platform-border-color;
        border-left: 6px solid $platform-border-color;
        border-right: 6px solid $platform-border-color;
        padding-top: 10px;
        background-color: $platform-main-background-color;
        height: 80px;
        .lights {
          background-color: $platform-main-background-color;
        }
      }
    }

    .platform-table {
      height: 112px;
      margin-left: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-top: 6px solid $platform-border-color;
      border-bottom: 6px solid $platform-border-color;
      border-right: 0;
      border-left: 0;
      background-color: $platform-main-background-color;
      padding-right: 8px;

      .platform-current-name {
        color: $platform-border-color;
        width: 500px;
        height: 100%;
        background-color: $platform-main-background-color;
        font-size: 34px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        overflow: hidden;

        &.long-name {
          font-size: 22px;
        }

        .current-division {
          font-size: 18px;
          &.long-name {
            font-size: 14px;
          }
        }
      }

      .platform-lift-name {
        width: 160px;
        height: 51px;
        padding: 8px;
        text-align: center;
        border-radius: 4px;
        background-color: $platform-border-color;
        color: $platform-main-background-color;
        margin: 8px;
        font-size: 30px;
      }

      .platform-weight-cell {
        width: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        text-align: center;
        margin: 8px;
        height: 51px;
        background-color: $platform-border-color;
        color: $platform-main-background-color;
        font-size: 30px;

        &.good {
          // background-color: #39b54a;
          background-color: $green;
          color: white;
        }
        &.bad {
          // background-color: #ed1c24;
          background-color: $red;
          color: white;
        }
        &.current-attempt {
          background-color: #333366;
          // color: $platform-current-attempt-font-color;
          color: $platform-main-background-color;
          border: 2px solid $platform-border-color;
          border-radius: 4px;
          color: white;
        }
      }

      .platform-best-lifts {
        width: 62px;
        display: flex;
        flex-direction: column;
        margin-left: 8px;

        .platform-best-lift {
          color: white;
          width: 62px;
          height: 24px;
          margin: 3px;
          padding-left: 4px;
          font-size: 14px;
          border-radius: 4px;
          // background-color: $platform-best-lifts-background-color;
          background-color: $green;
          display: flex;
          align-items: center;

          &.platform-best-lift-bomb {
            background-color: $red;
          }

          &.platform-best-lift-missing {
            color: $platform-current-attempt-font-color;
            background-color: $platform-main-background-color;
            border: 2px solid $platform-border-color;
            border-radius: 4px;
          }

          .platform-best-lift-label {
            // color: #055375;
            color: $platform-border-color;
            margin-right: 10px;
          }
        }
      }

      .platform-placements {
        font-size: 19px;
        display: flex;
        flex-direction: column;
        margin-left: 44px;

        sup {
          font-size: 10px;
          text-transform: none;
        }

        svg {
          width: 15px;
          height: 15px;
        }

        .platform-placement {
          border: 2px solid $platform-border-color;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          margin: 5px;
          height: 33px;

          .platform-placement-name {
            width: 145px;
            background-color: $platform-main-background-color;
            color: $platform-border-color;
            text-align: center;
            padding: 3px;
          }

          .platform-placement-change {
            width: 108px;
            background-color: $platform-border-color;
            color: $platform-main-background-color;
            text-align: center;
            padding: 3px;
          }
        }
      }
    }
  }

  .platform1,
  .platform2 {
    display: flex;
    flex-direction: row;
    padding-top: 120px;

    .lights {
      margin-left: 40px;
      margin-right: 40px;
    }
    .column {
      padding-right: 40px;
    }
  }

  .platform-name {
    width: 150px;
    min-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .platform2 {
    .platform-table {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      width: 700px;
      border: 1px solid $white;
      .platform-table-row {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        &:first-child {
          padding-top: 12px;
          padding-bottom: 12px;
        }
        &:last-child {
          padding-bottom: 12px;
        }
        div {
          margin: 0 8px;
          padding: 4px;
        }

        .weight-cell {
          width: 80px;
          border: 1px solid $white;
          border-radius: 4px;
          text-align: center;
          &.good {
            background-color: $green;
          }
          &.bad {
            background-color: $red;
          }
          &.current-attempt {
            background-color: #333366;
          }
        }
      }
    }
  }
}
