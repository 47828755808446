@import '../../../vars';

.display-index {
  height: calc(100% - 60px);
  font-weight: bold;

  .display-component {
    cursor: move;
    display: inline-block;

    &:hover {
      border: 1px dashed darkgray;

      .settings-button {
        display: block;
        position: absolute;
        right: 50%;
        top: 12px;
        height: 40px;
        width: 40px;
        border: 1px solid $bordercolor;
        z-index: 11;
      }

      .resizer {
        display: flex;
        position: absolute;
        top: 12px;
        left: 12px;
        background-color: $darkgray;
        height: 30px;
        border: 1px solid $bordercolor;
        align-items: center;
        justify-content: space-around;
        font-size: 24px;
        cursor: pointer;
        z-index: 11;
        color: $lightestgray;
      }

      .height-sizer {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 12px;
        right: 12px;
        background-color: $darkgray;
        height: 60px;
        border: 1px solid $bordercolor;
        align-items: center;
        justify-content: space-around;
        font-size: 24px;
        cursor: pointer;
        z-index: 11;

        .resize-button {
          display: flex;
          width: 30px;
          height: 30px;
          align-items: center;
          justify-content: center;
        }

        svg {
          height: 20px;
          width: 20px;
          fill: $lightestgray;
        }
      }
    }
    .resizer, .height-sizer, .settings-button {
      display: none;
    }
  }

  .lifter-details {
    white-space: nowrap;

    .record-attempt {
      color: $red;
    }
  }

  .order-table {

    .table-cell {
      font-size: inherit;
    }
  }

  .loading-chart {
    position: relative;

    .attempt-change-overlay {
      text-align: center;
      position:absolute;
      z-index: 10;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background:rgba(1,1,1,0.85);
      border: 4px solid red;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .rack-height-wrapper {
      position: absolute;
      bottom: 32%;
      left: 0;
      display: flex;
      justify-content: flex-end;

      .rack-height {
        white-space: nowrap;
      }
    }

    .lifter-name-wrapper {
      position: absolute;
      top: 10%;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .lifter-name {
        white-space: nowrap;
      }
    }

    .left-bar {
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: right;
      color: white;
    }
  }
}
