@import '../../../../vars';

.weigh-in-sheet {

  .weigh-in-sheet-options {
    width: 1000px;
    padding: 30px 0px;
    margin: 7px auto;
  }


  .session-pages {
    width: 1000px;
    padding: 50px;
    margin: 7px auto;
    border-radius: 4px;
    font-size: 16px;
    background-color: $white;
    color: $black;
    margin-bottom: 80px;
    page-break-after: always;
  }

  .meet-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px
  }

  table {
    border: 1px solid $black;
    border-collapse: collapse;
    width: 100%;

    td, th {
      border: 1px solid $black;
      padding: 6px;
    }
  }
}


@media print {
  @page {
    margin: 2.0cm;
  }

  body {
    background-color: $white !important;
  }

  .weigh-in-sheet {
    width: 100%;
    padding: 0px 0px;
    border-radius: 0px;

    .weigh-in-sheet-options {
      display: none;
    }

    .session-pages {
      padding-top: 0px;
      padding-bottom: 0px;
      page-break-after: always;
    }

    table {
      width: 100%;
    }
  }
}
