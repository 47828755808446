@import "../../../vars";

.results-index {
  height: 100%;

  .util-row {
    height: 48px;
    align-items: flex-start;
    display: flex;
    white-space: nowrap;

    button.export-button {
      width: 112px;
      margin-right: 12px;
    }

    button.forecasted {
      width: 112px;
      // margin-right: 12px;
    }
  }

  .table-wrapper {
    height: calc(100% - 48px);
  }
}

.small {
  .results-index {
    .util-row {
      justify-content: space-between;

      // button.export-button, button.forecasted {
      //   margin-right: 0;
      //   font-size: 12px;
      // }

      // button.forecasted {
      //   width: 98px;
      // }

      // button.export-button {
      //   max-width: 110px;
      //   white-space: normal;
      // }
    }
  }
}

.export-results-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 190;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($white, 0.2);
}

.export-results-modal {
  background-color: $darkestgray;
  border: 1px solid $bordercolor;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
  overflow: auto;
  max-height: 84vh;
  width: 600px;
  max-width: 90vw;

  .content {
    padding: 0 30px 10px 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .input-wrapper {
    margin-top: 10px;
  }

  .button-row {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    padding: 0 30px;
  }

  .radio-group {
    font-size: 18px;
    margin-top: 10px;

    .radio {
      margin-bottom: 18px;

      svg {
        width: 24px;
        height: 24px;
        fill: $lightestgray;
        margin-right: 8px;
      }
    }
  }
}
