@import '../../../../vars';

.jury-sheet {


  .flight-pages {
    width: 1200px;
    padding: 50px;
    margin: 7px auto;
    border-radius: 4px;
    font-size: 16px;
    background-color: $white;
    color: $black;
    page-break-after: always;
  }

  .meet-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px
  }

  svg {
    height: 12px;
    width: 12px;
  }

  table {
    border: 1px solid $black;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;

    th {
      font-size: 12px;
    }

    .name-cell {
      width: 220px;
      max-width: 220px;
    }

    .weight-class-cell {
      width: 100px;
      max-width: 100px;
    }

    .rack-cell {
      width: 100px;
      max-width: 100px;
    }

    .lift-cell {
      width: 60px;
      max-width: 60px;
    }

    td, th {
      border: 1px solid $black;
      padding: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td.lift-cell-wrapper {
      padding: 0;
    }
  }

  table.lift-cell {
    border: none;

    td {
      height: 24px;
      border: none;
      padding: 2px 8px;

      &:nth-child(2) {
        border-right: 1px solid $black;
        border-left: 1px solid $black;
      }
    }

    td.infraction-card {
      border-bottom: 1px solid $black;
    }
  }
}


@media print {
  @page {
    margin: 2.0cm;
    // size: landscape;
  }

  body {
    background-color: $white !important;
  }

  .jury-sheet {
    width: 100%;
    padding: 0px;
    border-radius: 0px;


    .flight-pages {
      padding-bottom: 0px;
      page-break-after: always;
    }

    table {
      width: 100%;
    }
  }
}
