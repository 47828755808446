@import "../../vars";

.password-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 190;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($white, 0.2);
}

.password-modal {
  width: 600px;
  min-height: 200px;
  max-width: 95vw;
  max-height: 95vh;
  z-index: 200;
  background-color: $darkestgray;
  border: 1px solid $lightestgray;
  overflow-y: auto;
  border-radius: 4px;

  .login-loading-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    background-color: gray;
  }

  .login-loading-text {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  .content {
    padding: 30px;
    text-align: center;

    .title {
      text-align: center;
      font-size: 18px;
      margin-bottom: 12px;
    }

    .confirm-password {
      margin-top: 12px;
    }

    .error {
      height: 22px;
      padding: 2px 0;
      color: red;
    }

    .password-cancel-button {
      margin-right: 20px;
    }

    .forgot-password-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 120px;
    }

    .button-row {
      display: flex;
      justify-content: right;

      button,
      input[type="submit"],
      input[type="button"] {
        min-width: 80px;
        text-align: center;
        display: block;
      }
    }

    @media (max-width: 400px) {
      .password-cancel-button {
        margin-right: 0;
        margin-bottom: 20px;
      }
      .button-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
