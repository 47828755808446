@import '../../vars';

.date-input {
  position: relative;

  div.error {
    color: red;
    font-size: 10px;
    position: absolute;
    margin: auto;
    right: 8px;
    top: 2px;
    overflow: auto;
    pointer-events: none;
  }
}

.medium {
  .date-input {

    div.error {
      font-size: 8px;
      right: 7px;
      top: 2px;

    }
  }
}

.small {
  .date-input {

    div.error {
      font-size: 5px;
      right: 4px;
      top: 1px;

    }
  }
}
