@import "../../../vars";

.board-index {
  height: 100%;
  overflow-x: hidden;

  .next-input-timer {
    margin-left: 12px;
    padding: 8px;
    border: 1px solid $lightestgray;
    border-radius: 4px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    &.past-due-attempt-input {
      border: 1px solid $red;
    }
    svg {
      height: 16px;
      width: 16px;
      fill: $lightestgray;
      margin-right: 4px;
    }
  }

  .util-row {
    height: 48px;
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
  }

  button.auto-scroll {
    width: 114px;
    margin-right: 12px;
  }

  .table-wrapper {
    height: calc(100% - 48px);
  }

  a.video-url {
    padding-left: 5px;
    svg {
      height: 16px;
      width: 16px;
      fill: $lightestgray;
    }
  }
}

.medium {
  padding-left: 4px;
  a.video-url {
    svg {
      height: 12px;
      width: 12px;
    }
  }
}

.small {
  .util-row {
    justify-content: space-between;

    .clock {
      display: none;
    }
  }

  a.video-url {
    padding-left: 3px;
    svg {
      height: 10px;
      width: 10px;
    }
  }
}
