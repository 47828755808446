@import '../../../../vars';

.check-in-sheet {
  width: 1000px;
  padding: 50px;
  margin: 7px auto;
  border-radius: 4px;
  font-size: 16px;
  background-color: $white;
  color: $black;

  .session-pages {
    padding-bottom: 80px;
    page-break-after: always;
  }

  .meet-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px
  }

  svg {
    height: 12px;
    width: 12px;
  }

  table {
    border: 1px solid $black;
    border-collapse: collapse;
    width: 100%;

    td, th {
      border: 1px solid $black;
      padding: 6px;
    }
  }
}


@media print {
  @page {
    margin: 2.0cm;
  }

  body {
    background-color: $white !important;
  }

  .check-in-sheet {
    width: 100%;
    padding: 0px;
    border-radius: 0px;

    .session-pages {
      padding-bottom: 0px;
      page-break-after: always;
    }

    table {
      width: 100%;
    }
  }
}
