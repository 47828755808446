@import '../../../../vars';

.results-sheet {

  .results-sheet-content {
    width: 1000px;
    padding: 50px;
    margin: 7px auto;
    border-radius: 4px;
    font-size: 16px;
    background-color: $white;
    color: $black;
    font-size: 18px;
  }

  .options-row {
    display: flex;
    justify-content: center;
  }

  .select-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .Select {
    width: 80px;
  }

  .awards-class-title {
    text-decoration: underline;
  }

  .weight-class-section {
    page-break-inside : avoid;
    padding-top: 40px;

    ol {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .meet-name {
    text-align: center;
    font-size: 24px;
  }
}

@media print {
  @page {
    margin: 2.0cm;
  }

  body {
    background-color: $white !important;
  }

  .results-sheet {
    width: 100%;
    border-radius: 0px;
    padding-top: 0;

    .select-wrapper {
      display: none;
    }

    .meet-name {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
    }


  }
}
