@import "../../vars";

.dropdown-menu {
  .dropdown-menu-toggle {
    margin-left: 8px;
    margin-right: 0;
    cursor: pointer;
    font-size: 12px;
  }
}

.dropdown-menu-popover {
  .dropdown-menu-popover-arrow {
    top: 1px !important;
  }
}

ul.dropdown-menu-ul {
  list-style: none;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  // border: 1px solid black;
  background-color: white;
  min-width: 215px;

  .dropdown-menu-close-top {
    height: 0;
  }
  .dropdown-menu-close-bottom {
    height: 0;
    overflow: hidden;
    button {
      height: 0;
      overflow: hidden;
    }
    &:focus {
      height: 40px;
    }
  }

  li.separator {
    padding-left: 8px;
    color: black;
    height: 20px;
    background-color: $lightgray;
    display: flex;
    align-items: center;
  }

  button {
    padding-right: 12px;
    padding-left: 12px;
    width: 100%;
    border: none;
    border-radius: 0;
    display: flex;
    justify-content: right;
    background-color: white;
    color: black;
    &:hover {
      background-color: $lightestgray;
    }
  }

  button.menu-check-button {
    svg {
      width: 14px;
      height: 14px;
      fill: black;
      margin-right: 4px;
    }
  }
}
