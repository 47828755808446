@import "../../../vars";

.run-index {
  height: 100%;
  overflow-x: hidden;

  .add-fourth-attempt-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .util-row {
    height: 48px;
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    // overflow-x: auto;
    // overflow-y: initial;

    .bar-and-collars-weight {
      display: flex;
      align-items: center;
      margin-left: 12px;

      select {
        min-width: 65px;
        margin-left: 12px;
      }
    }

    .custom-timer-length {
      display: flex;
      align-items: center;
      margin-left: 12px;

      select {
        min-width: 125px;
        margin-left: 12px;
      }

      button {
        margin-left: 8px;
      }
    }

    .attempts-count {
      margin: 0 12px;
      font-size: 24px;
    }
  }

  button.select-first-button {
    margin-right: 20px;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #cc919a;
    text-align: right;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #cc919a;
    text-align: right;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #cc919a;
    text-align: right;
  }

  .attempt-buttons {
    .button-row {
      margin-bottom: 4px;
      display: flex;
      justify-content: space-around;
    }

    button.attempt-button,
    a.attempt-button {
      font-size: 12px;
      margin-bottom: 4px;
      padding: 4px;
      svg {
        height: 24px;
        width: 24px;
        fill: $lightestgray;
      }
    }
  }

  .table-wrapper {
    height: calc(100% - 48px);
  }
}

.medium {
  .run-index {
    .attempt-buttons {
      button.attempt-button,
      a.attempt-button {
        font-size: 10px;
        margin-bottom: 3px;
        padding: 3px;

        svg {
          height: 17px;
          width: 17px;
        }

        &.open-attempt-menu-button {
          margin-left: 6px;
        }
      }
    }
  }
}

.small {
  .run-index {
    .util-row {
      justify-content: space-between;

      .bar-and-collars-weight,
      .custom-timer-length,
      .attempts-count {
        display: none;
      }
    }

    button.select-first-button {
      font-size: 12px;
      margin-right: 0;
    }

    .attempt-buttons {
      button.attempt-button,
      a.attempt-button {
        font-size: 8px;
        margin-bottom: 2px;
        padding: 2px;
        svg {
          height: 12px;
          width: 12px;
        }

        &.open-attempt-menu-button {
          margin-left: 4px;
        }
      }
    }
  }
}
