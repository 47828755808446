@import '../../vars';

.drawer {
  button {
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.drawer-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 190;
}

.drawer-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -260px;
  width: 260px;
  z-index: 200;
  background-color: $darkestgray;
  border-left: 1px solid $lightestgray;
  overflow-y: auto;

  // time here should match closeTimeoutMS prop on Modal component
  transition: all 400ms ease-in-out;

  &.drawer-modal-after-open {
    right: 0px;
  }
  &.drawer-modal-before-close {
    right: -260px;
  }

  .close-button {
    position: absolute;
    top: 8px;
    left: 8px;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;
      fill: $lightestgray;
    }
  }

  .clear-button {
    margin: 12px 0 8px;
  }

  .content {
    padding: 30px;

    .title {
      font-size: 18px;
    }

    .row {
      margin-top: 12px;
    }

    input {
      width: 100%;
    }
  }
}
